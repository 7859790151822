<template>
  <v-btn 
    class="homepage_button white--text" 
    color="grey" 
    depressed 
    width="300px" 
    height="70px"
    @click="$emit('click')"
    :to="to"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
  export default {
    props: ['to']
  }
</script>
